import React, { useState } from 'react';
import { createOrdenesCompra } from '../../api/ordenesCompra';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

interface FormData {
  OrCom_Proveedor: string;
  OrCom_FechaIngreso: string; 
  OrCom_Observaciones: string;
}

const CreateOrdenesCompra: React.FC<{ onClose: () => void }> = React.memo(({ onClose }) => {
  const [formData, setFormData] = useState<FormData>({
    OrCom_Proveedor: '',
    OrCom_FechaIngreso: '',
    OrCom_Observaciones: '', 
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    OrCom_Proveedor: '',
    OrCom_FechaIngreso: '',
    OrCom_Observaciones: '', 
  });

  const validate = () => {
    let tempErrors = {
      OrCom_Proveedor: '',
      OrCom_FechaIngreso: '',
      OrCom_Observaciones: '', 
    };
    if (!formData.OrCom_Proveedor.trim()) tempErrors.OrCom_Proveedor = 'El proveedor es requerido';
    if (!formData.OrCom_FechaIngreso) tempErrors.OrCom_FechaIngreso = 'La fecha es requerida';
    if (!formData.OrCom_Observaciones.trim()) tempErrors.OrCom_Observaciones = 'La observacion es requerida';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      console.log('Datos a enviar:', formData);
      await createOrdenesCompra(formData);
      onClose();
    } catch (error) {
      console.error('Error al crear la orden de compra:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form>
        <TextField
          label="Proveedor"
          name="OrCom_Proveedor"
          value={formData.OrCom_Proveedor}
          onChange={(e) => setFormData({ ...formData, OrCom_Proveedor: e.target.value })}
          fullWidth
          required
          sx={{ marginBottom: '1rem', marginTop: '1rem' }}
          size="small"
          error={!!errors.OrCom_Proveedor}
          helperText={errors.OrCom_Proveedor}
        />

        <TextField
          label="Fecha de Ingreso"
          type="date"
          name="OrCom_FechaIngreso"
          value={formData.OrCom_FechaIngreso}
          onChange={(e) =>
            setFormData({
              ...formData,
              OrCom_FechaIngreso: e.target.value,
            })
          }
          fullWidth
          required
          sx={{ marginBottom: '1rem' }}
          size="small"
          error={!!errors.OrCom_FechaIngreso}
          helperText={errors.OrCom_FechaIngreso}
          InputLabelProps={{
            shrink: true, 
          }}
        />

        <TextField
          label="Observacion"
          name="OrCom_Observaciones"
          value={formData.OrCom_Observaciones}
          onChange={(e) => setFormData({ ...formData, OrCom_Observaciones: e.target.value })}
          fullWidth
          required
          sx={{ marginBottom: '1rem', marginTop: '1rem' }}
          size="small"
          error={!!errors.OrCom_Observaciones}
          helperText={errors.OrCom_Observaciones}
        />

        <Box display="flex" justifyContent="space-between">
          <Button onClick={handleSubmit} variant="outlined" size="small" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Crear'}
          </Button>
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default CreateOrdenesCompra;
