import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../context/auth';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import { Add, Delete, Edit, Update, Visibility } from '@mui/icons-material';
import { deleteOrdenesCompra, getOrdenesCompra } from '../../api/ordenesCompra';
import CreateOrdenesCompra from './_Create';
import AddProductos from './_AddProductos';
import EditOrdenes from './_Edit';
import { ConfirmDelete } from '../../components/ConfirmDelete';
import ViewOrden from './_ViewById';
import moment from 'moment';

const OrdenesCompra = () => {
  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCreate, setOpenCreate] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAddProductos, setOpenAddProductos] = useState(false);
  const [selectedOrdenes, setSelectedOrdenes] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { permisos } = useAuth();
  const { Prm_OrdenesCompra_Ver, Prm_OrdenCompra_Crear, Prm_OrdenCompra_Modificar, Prm_OrdenCompra_Eliminar } = permisos;

  const handleOpenDeleteConfirm = useCallback((id: number) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setOpenConfirmDelete(false);
    setDeleteId(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (deleteId !== null) {
      await deleteOrdenesCompra(deleteId);
      fetchOrdenes();
      handleCloseDeleteConfirm();
    }
  }, [deleteId, handleCloseDeleteConfirm]);

  useEffect(() => {
    fetchOrdenes();
  }, []);

  const fetchOrdenes = useCallback(async () => {
    setLoading(true);
    const response = await getOrdenesCompra();
    if (response.isOk) {
      setOrdenesCompra(response.data);
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleOpenAddProductos = () => {
    setOpenAddProductos(true);
  };

  const handleCloseAddProductos = () => {
    setOpenAddProductos(false);
    // Aquí puedes recargar las tarifas o realizar otras acciones
  };

  const filteredOrdenes = ordenesCompra.filter((ordenes: any) => ordenes.OrCom_Observaciones && ordenes.OrCom_Observaciones.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <TextField label='Buscar' value={searchTerm} onChange={handleSearchChange} fullWidth size='small' />
            {Prm_OrdenCompra_Crear === 1 && (
              <IconButton onClick={() => setOpenCreate(true)} title='Nuevo' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
                <Add />
              </IconButton>
            )}
            <IconButton onClick={fetchOrdenes} title='Actualizar' sx={{ border: 'solid', marginLeft: '5px', borderColor: '#888', borderWidth: '1px', padding: '5px' }}>
              <Update />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>ID</TableCell>
                  <TableCell align='center'>Proveedor</TableCell>
                  <TableCell align='center'>Fecha de ingreso</TableCell>
                  <TableCell align='center'>Observaciones</TableCell>
                  <TableCell align='center'>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrdenes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ordenes: any) => (
                  <TableRow key={ordenes.OrCom_Id}>
                    <TableCell align='center'>{ordenes.OrCom_Id}</TableCell>
                    <TableCell align='center'>{ordenes.OrCom_Proveedor}</TableCell>
                    <TableCell align='center'>{moment(ordenes.OrCom_FechaIngreso).format('YYYY-MM-DD')}</TableCell>
                    <TableCell align='center'>{ordenes.OrCom_Observaciones}</TableCell>
                    <TableCell align='center'>
                      <IconButton
                        onClick={() => {
                          setSelectedOrdenes(ordenes);
                          setOpenView(true);
                        }}>
                        <Visibility />
                      </IconButton>
                      {Prm_OrdenCompra_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedOrdenes(ordenes);
                            setOpenAddProductos(true);
                          }}>
                          <Add />
                        </IconButton>
                      )}
                      {Prm_OrdenCompra_Modificar === 1 && (
                        <IconButton
                          onClick={() => {
                            setSelectedOrdenes(ordenes);
                            setOpenEdit(true);
                          }}>
                          <Edit />
                        </IconButton>
                      )}
                      {Prm_OrdenCompra_Eliminar === 1 && (
                        <IconButton onClick={() => handleOpenDeleteConfirm(ordenes.OrCom_Id)} title='Eliminar'>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination component='div' count={filteredOrdenes.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      )}

      <Dialog open={openCreate} onClose={() => setOpenCreate(false)} maxWidth='md' fullWidth>
        <DialogTitle>Crear Ordenes de compra</DialogTitle>
        <DialogContent>
          <CreateOrdenesCompra
            onClose={() => {
              setOpenCreate(false);
              fetchOrdenes();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openAddProductos} onClose={handleCloseAddProductos} maxWidth='md' fullWidth>
        <DialogTitle>Agregar productos a las ordenes de compra</DialogTitle>
        <DialogContent>
          <AddProductos ordenes={selectedOrdenes} onClose={handleCloseAddProductos} />
        </DialogContent>
      </Dialog>

      <Dialog open={openEdit} onClose={() => setOpenEdit(false)} maxWidth='md' fullWidth>
        <DialogTitle>Editar ordenes de compra</DialogTitle>
        <DialogContent>
          <EditOrdenes
            ordenes={selectedOrdenes}
            onClose={() => {
              setOpenEdit(false);
              fetchOrdenes();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openView} onClose={() => setOpenView(false)} maxWidth='md' fullWidth>
        <DialogTitle>Ver Listado de Ordenes</DialogTitle>
        <DialogContent>
          <ViewOrden ordenes={selectedOrdenes} onClose={() => setOpenView(false)} />
        </DialogContent>
      </Dialog>

      <ConfirmDelete open={openConfirmDelete} onClose={handleCloseDeleteConfirm} onConfirm={handleConfirmDelete} message='¿Estás seguro de que deseas eliminar esta orden de compra?' />
    </div>
  );
};

export default OrdenesCompra;
