import React, { useState, useEffect, useCallback } from 'react';
import { Button, TextField, Box, CircularProgress, Autocomplete, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getProductosEnInventario } from '../../api/productos';
import { addProductoToOrdenes, getDetalleOrdenesCompraById } from '../../api/ordenesCompra';

interface Producto {
  id: number;
  nombre: string;
  unidad: string;
}

interface ProductoOrdenes {
  OrComD_OrCom_Id: number;
  OrComD_Pr_Id: number;
  OrComD_Iva: number;
  OrComD_Valor: number;
  OrComD_Observaciones: string;
  Pr_Nombre: string;
}

const AddProductos = React.memo(({ ordenes, onClose }: any) => {
  const [productos, setProductos] = useState<Producto[]>([]);
  const [selectedProducto, setSelectedProducto] = useState<Producto | null>(null);
  const [iva, setIva] = useState<string>('');
  const [valor, setValor] = useState<string>('');
  const [observaciones, setObservaciones] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [productosOrdenes, setProductosOrdenes] = useState<ProductoOrdenes[]>([]);

  const fetchProductos = useCallback(async () => {
    try {
      const response = await getProductosEnInventario();
      if (response.isOk && response.data) {
        const mappedProductos = response.data.map((prod: any) => ({
          id: prod.Pr_Id,
          nombre: prod.Pr_Nombre,
          unidad: prod.Un_Nombre,
        }));
        setProductos(mappedProductos);
      } else {
        setProductos([]);
      }
    } catch (error) {
      console.error('Error inesperado al cargar los productos:', error);
    }
  }, []);

  const fetchOrdenesCompra = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getDetalleOrdenesCompraById(ordenes.OrCom_Id);
      if (response.isOk && response.data) {
        console.log("response.data",response.data);
        const mappedProductosOrdenes = response.data.map((prod: any) => ({
          OrComD_OrCom_Id: prod.OrComD_OrCom_Id,
          OrComD_Pr_Id: prod.OrComD_Pr_Id,
          OrComD_Iva: parseFloat(prod.OrComD_Iva) || 0,
          OrComD_Valor: parseFloat(prod.OrComD_Valor) || 0,
          OrComD_Observaciones: prod.OrComD_Observaciones,
          Pr_Nombre: prod.Pr_Nombre,
        }));
        setProductosOrdenes(mappedProductosOrdenes);
      } else {
        console.error('Error al cargar los productos de las ordenes:', response.message);
      }
    } catch (error) {
      console.error('Error inesperado al cargar los productos de las ordenes:', error);
    } finally {
      setLoading(false);
    }
  }, [ordenes.OrCom_Id]);

  useEffect(() => {
    fetchProductos();
    fetchOrdenesCompra();
  }, [fetchProductos, fetchOrdenesCompra]);

  const handleAddProducto = async () => {
    if (!selectedProducto || !iva || !valor) {
      alert('Debes completar todos los campos antes de agregar el producto.');
      return;
    }

    const newProducto: ProductoOrdenes = {
      OrComD_OrCom_Id: ordenes.OrCom_Id,
      OrComD_Pr_Id: selectedProducto.id,
      OrComD_Iva: parseFloat(iva),
      OrComD_Valor: parseFloat(valor),
      OrComD_Observaciones: observaciones,
      Pr_Nombre: selectedProducto.nombre + ' ' + selectedProducto.unidad,
    };

    setLoading(true);
    try {
      const response = await addProductoToOrdenes(newProducto);
      if (response.isOk) {
        setProductosOrdenes((prev) => [...prev, newProducto]);
        setSelectedProducto(null);
        setIva('');
        setValor('');
        setObservaciones('');
      } else {
        console.error('Error al agregar el producto:', response.message);
      }
    } catch (error) {
      console.error('Error inesperado al agregar el producto:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <p>
        <strong>Listado de ordenes:</strong> {ordenes.OrCom_Observaciones}
      </p>

      <Autocomplete
        options={productos}
        getOptionLabel={(option) => option.nombre}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedProducto}
        onChange={(event, value) => setSelectedProducto(value)}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.nombre} {option.unidad}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label='Producto' variant='outlined' fullWidth sx={{ marginBottom: '1rem' }} />}
      />

      <TextField label='Iva' value={iva} onChange={(e) => setIva(e.target.value)} type='number' fullWidth sx={{ marginBottom: '1rem' }} />

      <TextField label='Valor' value={valor} onChange={(e) => setValor(e.target.value)} type='number' fullWidth sx={{ marginBottom: '1rem' }} />

      <TextField label='Observaciones' value={observaciones} onChange={(e) => setObservaciones(e.target.value)} type='string' fullWidth sx={{ marginBottom: '1rem' }} />

      <Box display='flex' justifyContent='space-between' sx={{ marginBottom: '1rem' }}>
        <Button onClick={handleAddProducto} variant='outlined' disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Agregar Producto'}
        </Button>

        <Button onClick={onClose} variant='outlined' color='error'>
          Cerrar
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: '1rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID Producto</TableCell>
              <TableCell>Nombre Producto</TableCell>
              <TableCell align='center'>Iva</TableCell>
              <TableCell align='center'>Valor</TableCell>
              <TableCell>Observaciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productosOrdenes.map((producto, index) => (
              <TableRow key={`${producto.OrComD_OrCom_Id}-${producto.OrComD_Pr_Id}-${index}`}>
                <TableCell align='center'>{producto.OrComD_Pr_Id}</TableCell>
                <TableCell>{producto.Pr_Nombre}</TableCell>
                <TableCell align='center'>{producto.OrComD_Iva.toFixed(2)}</TableCell>
                <TableCell align='center'>{producto.OrComD_Valor.toFixed(2)}</TableCell>
                <TableCell>{producto.OrComD_Observaciones}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default AddProductos;
