import { UseApi } from '../utils/axios-pettitions';

export async function getOrdenesCompra() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener las ordenes',
    };
  }
}

export async function getOrdenesCompraById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener la lista de ordenes de compra',
    };
  }
}

export async function createOrdenesCompra(Ordenes: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra', 'POST', null, Ordenes);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear las ordenes de compra',
    };
  }
}

export async function updateOrdenesCompra(id: number, Ordenes: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra', 'PUT', { id }, Ordenes);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar la orden',
    };
  }
}

export async function deleteOrdenesCompra(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar la lista de ordenes de compra',
    };
  }
}

export async function getDetalleOrdenesCompraById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra/detalle', 'GET', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener la lista de ordenes de compra',
    };
  }
}

export async function addProductoToOrdenes(OrdenesDetalle: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra/detalle', 'POST', null, OrdenesDetalle);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el lista de ordenes de compra',
    };
  }
}

export async function updateDetalleOrdenesCompra(id: number, ordenes: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra/detalle', 'PUT', { id }, ordenes);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar las ordenes',
    };
  }
}

export async function deleteDetalleOrdenesCompra(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('ordenesCompra/detalle', 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el producto de las ordenes de compra',
    };
  }
}
