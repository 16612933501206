import { UseApi } from '../utils/axios-pettitions';

export async function getResumenGanancas() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('resumenGanancias', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los resumenes',
    };
  }
}