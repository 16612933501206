import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Stack, Typography, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Button, TableContainer, Paper, CircularProgress } from '@mui/material';
import { getPermisos, updatePermisos } from '../../../api/usuarios';

const permissionsData = [
  { name: 'Servicios', view: 'Prm_Serv_Ver', create: 'Prm_Serv_Crear', edit: 'Prm_Serv_Modificar', delete: null, other: 'Prm_Serv_Camb_Estado' },
  { name: 'Inventario', view: 'Prm_Invent_Ver', create: 'Prm_Invent_Crear', edit: 'Prm_Invent_Modificar', delete: 'Prm_Invent_Eliminar', other: null },
  { name: 'Liquidaciones', view: 'Prm_Liquid_Ver', create: 'Prm_Liquid_Crear', edit: 'Prm_Liquid_Modificar', delete: 'Prm_Liquid_Eliminar', other: 'Prm_Liquid_Camb_Estado' },
  { name: 'Creditos', view: 'Prm_Credito_Ver', create: 'Prm_Credito_Cambiar', edit: 'Prm_Credito_Abonar', delete: null, other: null },
  { name: 'Materiales', view: 'Prm_Mat_Ver', create: 'Prm_Mat_Crear', edit: 'Prm_Mat_Modificar', delete: "Prm_Mat_Eliminar", other: null },
  { name: 'Configuración', view: 'Prm_Config', create: null, edit: null, delete: null, other: null },
  { name: 'Config. Usuarios', view: 'Prm_Config_Usr_Ver', create: 'Prm_Config_Usr_Crear', edit: 'Prm_Config_Usr_Modificar', delete: 'Prm_Config_Usr_Eliminar', other: null },
  { name: 'Config. Instaladores', view: 'Prm_Config_Instal_Ver', create: 'Prm_Config_Instal_Crear', edit: 'Prm_Config_Instal_Modificar', delete: 'Prm_Config_Instal_Eliminar', other: null },
  { name: 'Config. Tipos de Identificación', view: 'Prm_Config_Tip_Id_Ver', create: 'Prm_Config_Tip_Id_Crear', edit: 'Prm_Config_Tip_Id_Modificar', delete: 'Prm_Config_Tip_Id_Eliminar', other: null },
  { name: 'Config. Clientes', view: 'Prm_Config_Cltes_Ver', create: 'Prm_Config_Cltes_Crear', edit: 'Prm_Config_Cltes_Modificar', delete: 'Prm_Config_Cltes_Eliminar', other: null },
  { name: 'Config. Productos', view: 'Prm_Config_Prod_Ver', create: 'Prm_Config_Prod_Crear', edit: 'Prm_Config_Prod_Modificar', delete: 'Prm_Config_Prod_Eliminar', other: null },
  { name: 'Config. Categorías', view: 'Prm_Config_Categ_Ver', create: 'Prm_Config_Categ_Crear', edit: 'Prm_Config_Categ_Modificar', delete: 'Prm_Config_Categ_Eliminar', other: null },
  { name: 'Config. Unidades', view: 'Prm_Config_Unid_Ver', create: 'Prm_Config_Unid_Crear', edit: 'Prm_Config_Unid_Modificar', delete: 'Prm_Config_Unid_Eliminar', other: null },
  { name: 'OrdenesCompra', view: 'Prm_OrdenCompra_Ver', create: 'Prm_OrdenCompra_Crear', edit: 'Prm_OrdenCompra_Modificar', delete: 'Prm_OrdenCompra_Eliminar', other: null },
  { name: 'ResumenGanancias', view: 'Prm_ResumenGanan_Ver', create: 'Prm_ResumenGanan_Crear', edit: 'Prm_ResumenGanan_Modificar', delete: 'Prm_ResumenGanan_Eliminar', other: null },
];

export function Permisos({ open, onClose, currentData }: { open: boolean; onClose: () => void; currentData: any }) {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const handlePermissionChange = (permissionType: any, module: any, checked: any) => {
    setData((prev: any) => ({
      ...prev,
      [module[permissionType]]: checked,
    }));
  };

  const filteredItems = permissionsData;

  useEffect(() => {
    if (open) {
      setLoading(true);
      getPermisos(currentData.Pe_Id)
        .then((response) => {
          setData(response.data.permisos[0]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, currentData]);

  const handleSave = () => {
    const id = data.Prm_Id;
    delete data.Prm_Id;
    updatePermisos(id, data)
      .then((res) => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='xl'>
      <DialogTitle>Administración de permisos </DialogTitle>
      <DialogContent sx={{ marginBottom: 1 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table
              size='small'
              aria-label='permissions table'
              sx={{
                'tbody tr:nth-of-type(odd)': {
                  backgroundColor: '#f5f5f5', // Color de fondo para las filas impares
                },
                'tbody tr:nth-of-type(even)': {
                  // Color de fondo para las filas pares
                },
              }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='body2' fontWeight='bold'>
                      Módulo
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography variant='body2' fontWeight='bold'>
                      Consultar
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography variant='body2' fontWeight='bold'>
                      Agregar
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography variant='body2' fontWeight='bold'>
                      Modificar
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography variant='body2' fontWeight='bold'>
                      Eliminar
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography variant='body2' fontWeight='bold'>
                      Otros
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredItems.map((module: any) => (
                  <TableRow key={module.name}>
                    <TableCell>
                      <Typography variant='body2' fontWeight='bold'>
                        {module.name.toUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>{module.view ? <Checkbox checked={data[module.view] || false} onChange={(e) => handlePermissionChange('view', module, e.target.checked)} /> : 'N/A'}</TableCell>
                    <TableCell align='center'>{module.create ? <Checkbox checked={data[module.create] || false} onChange={(e) => handlePermissionChange('create', module, e.target.checked)} /> : 'N/A'}</TableCell>
                    <TableCell align='center'>{module.edit ? <Checkbox checked={data[module.edit] || false} onChange={(e) => handlePermissionChange('edit', module, e.target.checked)} /> : 'N/A'}</TableCell>
                    <TableCell align='center'>{module.delete ? <Checkbox checked={data[module.delete] || false} onChange={(e) => handlePermissionChange('delete', module, e.target.checked)} /> : 'N/A'}</TableCell>
                    <TableCell align='center'>{module.other ? <Checkbox checked={data[module.other] || false} onChange={(e) => handlePermissionChange('other', module, e.target.checked)} /> : 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ mt: 3 }}>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              onClose();
            }}>
            Cancelar
          </Button>
          <Button onClick={handleSave} variant='contained'>
            Guardar
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
