import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { getResumenGanancas } from '../../api/resumenGanancias';
import moment from 'moment';
import { Update } from '@mui/icons-material';

const ResumenGanancias = () => {
  const [resumenGanancias, setResumenGanancias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  useEffect(() => {
    fetchResumenes();
  }, []);

  const fetchResumenes = useCallback(async () => {
    setLoading(true);
    const response = await getResumenGanancas();
    if (response.isOk) {
      const groupedData = response.data.reduce((acc: any[], item: any) => {
        const existing = acc.find((resumen) => resumen.OrComD_Pr_Id === item.OrComD_Pr_Id);

        if (existing) {
          existing.OrComD_Valor += parseFloat(item.OrComD_Valor) || 0;
          existing.Srv_Total += parseFloat(item.Srv_Total) || 0;
          existing.SrvD_Iva += parseFloat(item.SrvD_Iva) || 0;
          existing.SrvD_Total += parseFloat(item.SrvD_Total) || 0;
        } else {
          acc.push({ ...item,
            OrComD_Valor: parseFloat(item.OrComD_Valor) || 0,
            Srv_Total: parseFloat(item.Srv_Total) || 0,
            SrvD_Iva: parseFloat(item.SrvD_Iva) || 0,
            SrvD_Total: parseFloat(item.SrvD_Total) || 0,
           });
        }

        return acc;
      }, []);

      setResumenGanancias(groupedData);
    }
    setLoading(false);
  }, []);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleStartDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  }, []);

  const handleEndDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const filteredResumenes = resumenGanancias.filter((resumenes: any) => {
    const servicioFecha = moment(resumenes.OrCom_FechaIngreso).startOf('day');
    const startDateNormalized = startDate ? moment(startDate).startOf('day') : null;
    const endDateNormalized = endDate ? moment(endDate).endOf('day') : null;

    const matchesSearchTerm =
      resumenes.Srv_VehiculoMarca.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resumenes.Srv_VehiculoPlacaOSerial.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resumenes.Cl_Nombre.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesStartDate = startDateNormalized === null || servicioFecha.isSameOrAfter(startDateNormalized);

    const matchesEndDate = endDateNormalized === null || servicioFecha.isSameOrBefore(endDateNormalized);

    return matchesSearchTerm && matchesStartDate && matchesEndDate;
  });

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <Grid container spacing={2} alignItems="center" mb={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label="Buscar" value={searchTerm} onChange={handleSearchChange} fullWidth size="small" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Fecha Inicio"
                type="date"
                value={startDate || ''}
                onChange={handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Fecha Fin"
                type="date"
                value={endDate || ''}
                onChange={handleEndDateChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <IconButton
                onClick={fetchResumenes}
                title="Actualizar"
                sx={{ border: 'solid', borderColor: '#888', marginLeft: '3px', borderWidth: '1px', padding: '5px' }}
              >
                <Update />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID Orden de Compra</TableCell>
                  <TableCell align="center">Proveedor</TableCell>
                  <TableCell align="center">Nombre del producto</TableCell>
                  <TableCell align="center">Fecha de ingreso</TableCell>
                  <TableCell align="center">Iva</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Total del servicio</TableCell>
                  <TableCell align="center">Iva del servicio</TableCell>
                  <TableCell align="center">Total del servicio del instalador</TableCell>
                  <TableCell align="center">Ganancia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredResumenes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((resumenes: any) => (
                  <TableRow key={resumenes.OrCom_Id}>
                    <TableCell align="center">{resumenes.OrCom_Id}</TableCell>
                    <TableCell align="center">{resumenes.OrCom_Proveedor}</TableCell>
                    <TableCell align="center">{resumenes.SrvD_Pr_Nombre}</TableCell>
                    <TableCell align="center">{moment(resumenes.OrCom_FechaIngreso).format('YYYY-MM-DD')}</TableCell>
                    <TableCell align="center">{resumenes.OrComD_Iva}</TableCell>
                    <TableCell align="center">{resumenes.OrComD_Valor}</TableCell>
                    <TableCell align="center">{resumenes.Srv_Total}</TableCell>
                    <TableCell align="center">{resumenes.SrvD_Iva}</TableCell>
                    <TableCell align="center">{resumenes.SrvD_Total}</TableCell>
                    <TableCell align="center">{resumenes.Srv_Total - resumenes.OrComD_Valor}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredResumenes.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </div>
  );
};

export default ResumenGanancias;
