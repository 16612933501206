import React, { useState, useEffect } from 'react';
import { updateOrdenesCompra } from '../../api/ordenesCompra';
import { Button, TextField, Box, CircularProgress } from '@mui/material';

const EditOrdenes = React.memo(({ ordenes, onClose }: any) => {
  const [formData, setFormData] = useState(ordenes);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    OrCom_Proveedor: '',
    OrCom_FechaIngreso: '',
    OrCom_Observaciones: '', 
  });

  useEffect(() => {
    setFormData(ordenes);
  }, [ordenes]);

  const validate = () => {
    let tempErrors = {
      OrCom_Proveedor: '',
      OrCom_FechaIngreso: '',
      OrCom_Observaciones: '', 
    };
    if (!formData.OrCom_Proveedor.trim()) tempErrors.OrCom_Proveedor = 'El proveedor es requerido';
    if (!formData.OrCom_FechaIngreso) tempErrors.OrCom_FechaIngreso = 'La fecha es requerida';
    if (!formData.OrCom_Observaciones.trim()) tempErrors.OrCom_Observaciones = 'La observacion es requerida';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await updateOrdenesCompra(formData.OrCom_Id, formData);
      onClose();
    } catch (error) {
      console.error('Error al actualizar las ordenes:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form>
        <TextField
          label="Proveedor"
          name="OrCom_Proveedor"
          value={formData.OrCom_Proveedor}
          onChange={handleChange}
          fullWidth
          required
          sx={{ marginBottom: '1rem', marginTop: '1rem' }}
          size="small"
          error={!!errors.OrCom_Proveedor}
          helperText={errors.OrCom_Proveedor}
        />

        <TextField
            label="Fecha de Ingreso"
            type="date"
            name="OrCom_FechaIngreso"
            value={formData.OrCom_FechaIngreso}
            onChange={handleChange}
            fullWidth
            required
            sx={{ marginBottom: '1rem' }}
            size="small"
            error={!!errors.OrCom_FechaIngreso}
            helperText={errors.OrCom_FechaIngreso}
            InputLabelProps={{
            shrink: true, 
            }}
        />

        <TextField
          label="Observaciones"
          name="OrCom_Observaciones"
          value={formData.OrCom_Observaciones}
          onChange={handleChange}
          fullWidth
          required
          sx={{ marginBottom: '1rem', marginTop: '1rem' }}
          size="small"
          error={!!errors.OrCom_Observaciones}
          helperText={errors.OrCom_Observaciones}
        />

        <Box display="flex" justifyContent="space-between">
          <Button onClick={handleSubmit} variant="outlined" size="small" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Actualizar'}
          </Button>
          <Button onClick={onClose} variant="outlined" color="error" size="small">
            Cancelar
          </Button>
        </Box>
      </form>
    </div>
  );
});

export default EditOrdenes;
