import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CircularProgress } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useAuth } from '../../context/auth';
import { ConfirmDelete } from '../../components/ConfirmDelete';
import { deleteDetalleOrdenesCompra, getDetalleOrdenesCompraById, updateDetalleOrdenesCompra } from '../../api/ordenesCompra';

const ViewOrden = React.memo(({ ordenes, onClose }: any) => {
  const [productos, setProductos] = useState([]);
  const { permisos } = useAuth();
  const { Prm_OrdenCompra_Modificar, Prm_OrdenCompra_Eliminar } = permisos;
  const [selectedOrden, setSelectedOrden] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [editedValues, setEditedValues] = useState({ idProducto: 0, iva: '', valor: '', observaciones: '' });

  const fetchProductos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getDetalleOrdenesCompraById(ordenes.OrCom_Id);
      if (response.isOk && response.data) {
        setProductos(response.data);
      } else {
        setProductos([]);
      }
    } catch (error) {
      console.error('Error inesperado al cargar los productos:', error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchProductos();
  }, []);

  const handleOpenDeleteConfirm = useCallback((id: number) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  }, []);

  const handleCloseDeleteConfirm = useCallback(() => {
    setOpenConfirmDelete(false);
    setDeleteId(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (deleteId !== null) {
      await deleteDetalleOrdenesCompra(deleteId);
      fetchProductos();
      handleCloseDeleteConfirm();
    }
  }, [deleteId, handleCloseDeleteConfirm]);

  const handleEditClick = (index: number, producto: any) => {
    setEditingRow(index);
    setEditedValues({
      idProducto: producto.OrComD_Pr_Id,
      iva: producto.OrComD_Iva.toString(),
      valor: producto.OrComD_Valor.toString(),
      observaciones: producto.OrComD_Observaciones,
    });
  };

  const handleSave = async (detalleId: number) => {
    try {
      const updatedProducto = {
        OrComD_Pr_Id: editedValues.idProducto,
        OrComD_Iva: parseFloat(editedValues.iva),
        OrComD_Valor: parseFloat(editedValues.valor),
        OrComD_Observaciones: editedValues.observaciones,
      };
      await updateDetalleOrdenesCompra(detalleId, updatedProducto);
      fetchProductos();
      setEditingRow(null);
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div style={{ marginTop: '5px' }}>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <form>
          <TextField label='Proveedor' value={ordenes.OrCom_Proveedor} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' />
          <TextField label='Fecha' value={ordenes.OrCom_FechaIngreso} fullWidth disabled sx={{ marginBottom: '1rem', marginTop: '1rem' }} size='small' />

          <TableContainer component={Paper} sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                    <TableCell align='center'>ID Producto</TableCell>
                    <TableCell>Nombre Producto</TableCell>
                    <TableCell align='center'>Iva</TableCell>
                    <TableCell align='center'>Valor</TableCell>
                    <TableCell>Descripción</TableCell>
                    <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productos.map((producto: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell align='center'>{producto.OrComD_Id}</TableCell>
                    <TableCell>{producto.Pr_Nombre}</TableCell>
                    <TableCell align="center">
                      {editingRow === index ? (
                        <TextField
                          name="iva"
                          value={editedValues.iva}
                          onChange={handleChange}
                          onBlur={() => handleSave(producto.OrComD_Id)}
                          onKeyDown={(e) => e.key === 'Enter' && handleSave(producto.OrComD_Id)}
                          size="small"
                        />
                      ) : (
                        producto.OrComD_Iva !== null && !isNaN(producto.OrComD_Iva) ? parseFloat(producto.OrComD_Iva).toFixed(2) : 'N/A'
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRow === index ? (
                        <TextField
                          name="valor"
                          value={editedValues.valor}
                          onChange={handleChange}
                          onBlur={() => handleSave(producto.OrComD_Id)}
                          onKeyDown={(e) => e.key === 'Enter' && handleSave(producto.OrComD_Id)}
                          size="small"
                        />
                      ) : (
                        producto.OrComD_Valor !== null && !isNaN(producto.OrComD_Valor) ? parseFloat(producto.OrComD_Valor).toFixed(2) : 'N/A'
                      )}
                    </TableCell>  
                    <TableCell align="center">
                      {editingRow === index ? (
                        <TextField
                          name="observaciones"
                          value={editedValues.observaciones}
                          onChange={handleChange}
                          onBlur={() => handleSave(producto.OrComD_Id)}
                          onKeyDown={(e) => e.key === 'Enter' && handleSave(producto.OrComD_Id)}
                          size="small"
                        />
                      ) : (
                        producto.OrComD_Observaciones !== null ? producto.OrComD_Observaciones : ''
                      )}
                    </TableCell>
                    <TableCell>
                      {Prm_OrdenCompra_Modificar === 1 && (
                        <IconButton onClick={() => handleEditClick(index, producto)} title='Editar'>
                          <Edit />
                        </IconButton>
                      )}
                      {Prm_OrdenCompra_Eliminar === 1 && (
                        <IconButton onClick={() => handleOpenDeleteConfirm(producto.OrComD_Id)} title='Eliminar'>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display='flex' justifyContent='flex-end'>
            <Button onClick={onClose} variant='outlined' color='error' size='small'>
              Cerrar
            </Button>
          </Box>
          <ConfirmDelete open={openConfirmDelete} onClose={handleCloseDeleteConfirm} onConfirm={handleConfirmDelete} message='¿Estás seguro de que deseas eliminar este producto del listado?' />
        </form>
      )}
    </div>
  );
});

export default ViewOrden;
